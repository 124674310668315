import { requestAPI } from './request';
import i18n from 'i18next';
import { store } from '../store';
import { homescreen } from '../actions';

import { getUserLanguage } from '../../helper/LanguageHelper';

export const API = {
  POST: 'post',
  GET: 'get',
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  GET_TIME: 'api/v1/current-time',
  API_LOGIN: 'api/v1/social-login',
  HOME_SCREEN: 'api/v1/home-screen',
  MATCH_DETAIL: 'api/v1/dating/get-matched-details',
  SET_LANGUAGE: 'api/v1/user/set-language',
  SET_PARTICIPATION: 'api/v1/date/participate',
  REFRESH_TOKEN: 'api/v1/generate-token',
  CANCEL_DATE: 'api/v1/dating/cancel-date',
  DEVICE_TOKEN: 'api/v1/user/save-device-token',
  CUSTOMER_SUPPORT: 'api/v1/notify-admin',
  DOCUMENT_UPLOAD: 'api/v1/user/upload-required-document',
  USER_DEACTIVE: '/api/v1/user/deactive-profile',
  USER_CANCEL: '/api/v1/user/cancel-profile',
  API_LOGOUT: '/api/v1/user/logout',
  DELETE_POPUP: '/api/v1/popup-delete',
  CANCEL_DATE_PARTNER: '/api/v1/dating/cancel-date-by-partner',
  GET_BODY_SHAPE: '/api/v1/dating/get-body-shape',
  GET_PREFRENCES: '/api/v1/dating/get-important-preferences',
  FEEDBACK: '/api/v1/dating/save-feedback-dating',
  GET_FEEDBACK: 'api/v1/get-previous-date-data',
  GET_USER_PROFILE: '/api/v1/user/get-profile',
  ACTIVE_PROFILE: '/api/v1/user/active-profile',
  USE_COUPON: '/api/v1/user/use-coupon',
  GET_POPUP: '/api/v1/get-popup',
  GET_DEACTIVATE_OPTIONS: 'api/v1/cancelDeactivateOptions',
  GET_CAFE: '/api/v1/get-nearest-cafe-data',
  GET_NOTIFICATIONS: '/api/v1/notifications',
  GET_CHAT_ROOM: '/api/v1/chatkit/getRoomByUserId',
  SAVE_CHAT_HISTORY: '/api/v1/chatkit/sendMessage',
  GET_CHAT_MESSAGE: '/api/v1/chatkit/message',
  GET_CHAT_ROOM_V2: '/api/v1/chatkit/getRoomWithPartnerId', // This is API get Chat Room for web app.
  CONFIRM_CHANGE_COST_PLAN: '/api/v1/user/confirm-change-cost-plan',
  CANCEL_CHANGE_COST_PLAN: '/api/v1/user/cancel-change-cost-plan',
  DEACTIVE_PROFILE: '/api/v1/user/deactivate-profile'
};

export const requestSetLanguage = (params, timeout) =>
  requestAPI(API.SET_LANGUAGE, API.POST, params, timeout);

export const requestHomeScreen = (lngId = 1) => {
  const lngMap = {
    1: 'en/us',
    2: 'ja/jp'
  };

  const i18nMap = {
    1: 'en',
    2: 'jp'
  };

  const languageId = getUserLanguage();

  const homescreenAction = homescreen(
    { body: null, langId: lngMap[lngId] },
    response => {
      i18n.changeLanguage(i18nMap[languageId]);
    }
  );
  store.dispatch(homescreenAction);
};
