import { combineReducers } from 'redux';
import servermaintain from './serverRepair';
import app from './appReducer';
import comm from './commonReducer';
import err from './errorReducer';
import auth from './authReducer';
import homescreen from './homescreenReducer';
import match from './matchReducer';
import participation from './participationReducer';
import popups from './popupsReducer';
import userProfile from './userProfileReducer';
import useCoupon from './useCouponReducer';
import cancelDate from './cancelDateReducer';
import chatRoom from './chatRoomReducer';
import chatHistory from './chatHistoryReducer';
import cafe from './cafeReducer';
import chatMessages from './chatMessagesReducer';
import changePlan from './changePlanReducer';
import drawer from './drawerReducer';
import deactiveProfile from './deactiveProfileReducer';
import cancelProfile from './cancelProfileReducer';

export default combineReducers({
  comm,
  servermaintain,
  app,
  err,
  auth,
  homescreen,
  match,
  participation,
  popups,
  userProfile,
  useCoupon,
  cancelDate,
  chatRoom,
  chatHistory,
  cafe,
  chatMessages,
  changePlan,
  drawer,
  changePlan,
  deactiveProfile,
  cancelProfile
});
