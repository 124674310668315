import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'recompose';
import { any, equals } from 'ramda';
import { LoadingSpinner } from '../../components/Loading';

const mapStateToProps = state => {
  return {
    // To show the global loading indicator,
    // just add the reducer's loading state here
    showLoading: any(equals(true))([
      state.auth.isLoading,
      state.cancelDate.isLoading,
      state.homescreen.isLoading,
      state.match.isLoading,
      state.participation.isLoading,
      state.popups.isLoading,
      state.useCoupon.isLoading,
      state.userProfile.isLoading,
      state.app.isLoading,
      state.chatRoom.isLoading,
      state.cafe.isLoading,
      state.changePlan.isLoading,
      state.cancelProfile.isLoading,
      state.deactiveProfile.isLoading
    ])
  };
};

const LoadingSpinnerContainer = compose(
  connect(mapStateToProps),
  branch(({ showLoading }) => !showLoading, renderNothing)
)(LoadingSpinner);

export { LoadingSpinnerContainer as LoadingSpinner };
